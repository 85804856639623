#join {
    background-color: #f6f8fb;
  
  }
  .service-box {
  
    padding: 2rem;
    border: 1px solid #eef1f5;
    // box-shadow: 2px 2px 4px #e9ebef;
    margin-bottom: 1rem;
    background: white;
    .service-icon {
      font-size: 2rem;
      color: #673fb4;
    }
    .cards{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center

    }
    .section-content{
        width: 100vw;
        height: 100vh;
        background: #6C7A89;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center
    }
    .service-body {
      .service-title {
        font-size: 1.1rem;
        color: #4e4e4e;
        margin-bottom: 1.2rem;
      }
      .service-description {
        font-size: 0.9rem;
        color: #6f6f6f;
      }
      
    }
  }
  