.driverImage {
  width: 100%;
  max-height: 400px;
  img {
    padding: 5px;
    width: 80%;
    object-fit: cover;
    object-position: center;
    
  }
}
.service-icon {
  font-size: 3rem;
  color: #673fb4;
}
.driver-description {
  padding: 5px;
  margin-bottom: 100px;
  margin-top: 50px;
  font-size: 0.9rem;
  color: #673fb4;
  justify-content: center;
}
.driver-description-page {
  font-size: 1.3rem;
  color: #673fb4;
  justify-content: center;
  align-content: center;
}
