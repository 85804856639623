$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import './partials/header';
@import './partials/home';
@import './partials/about';
@import './partials/driver';
@import './partials/services';
@import './partials/citys';
@import './partials/blog';
@import './partials/footer';
@import './partials/join';


@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}
.buttons {
	box-shadow:inset 0px 0px 0px 0px #efdcfb;
	background:linear-gradient(to bottom, #673fb4 5%, #8736c5 100%);
	background-color:#673fb4;
	border-radius:10px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:'Raleway', sans-serif;
	font-size:15px;
	font-weight:bold;
	padding:8px 20px;
	text-decoration:none;
  text-shadow:1px -2px 10px #000000;
  margin:5px;
}
.buttonJoin{
  display: inline-block;
text-align: center; 
margin-left: 50%; 
margin-right: 50%; 
overflow: visible; 
}
.buttons-contact{
	box-shadow:inset 0px 0px 0px 0px #efdcfb;
	background:linear-gradient(to bottom, #b6a1df 5%, #dfdbe2 100%);
	background-color:#673fb4;
	border-radius:10px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:'Raleway', sans-serif;
	font-size:15px;
	font-weight:bold;
	padding:8px 20px;
	text-decoration:none;
  text-shadow:1px -2px 10px #000000;
  margin: 0 15px;
}
.buttons:hover {
	background:linear-gradient(to bottom, #bc80ea 5%, #673fb4 100%);
	background-color:#8736c5;
}
.buttons:active {
	position:relative;
	top:1px;
}

        
.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Raleway', sans-serif;
    position: relative;
    margin-bottom: 2rem;
    span {
      color: #673fb4;
    }
    a {
      color: white;
      text-decoration: none;
    }
    
    &:after {
      content: '';
      content: '';
      display: block;
      height: 3px;
      width: 70px;
      background:#673fb4;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .title-page {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Raleway', sans-serif;
    position: relative;
    span {
      color: #673fb4;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  
  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 0.9rem;
  }
}
