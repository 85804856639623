#citys {
  .citys-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #673fb4;
      opacity: 0.8;
    }

    
    .citys-icon {
      font-size: 2.0rem;
      color: #ffffff;

    }
    .citys-title {
      font-size: 0.8rem;
      color: #ffffff;

    }
    .citys-counter {
      font-size: 1.5rem;
      font-weight: 200;
    }
  }
}
